<template>
  <header class="bg-white py-4" style="position: sticky; top: 0; z-index: 99;">
    <nav class="container mx-auto">
      <img src="/img/logo.png" alt="" srcset="" width="190">
    </nav>
  </header>

  <main class="container min-h-screen mt-10">
    <section class="head grid sm:grid-cols-2 grid-cols-1">
      <div class="flex flex-col justify-center">
        <div class="md:text-5xl text-4xl font-bold">
          <span class="text-blue-400">Международная&nbsp;</span>
          <span class="md:hidden block"></span>
          <span>доставка в Узбекистан</span>
        </div>

        <p class="text-xl mt-9">Международная доставка</p>
        <!-- <ul class="text-xl">
          <li>Международная доставка</li>
          <li>Международная доставка</li>
          <li>Международная доставка</li>
        </ul> -->

        <div>
          <a
            :href="botUrl"
            target="_blank"
            class="inline-flex items-center px-6 py-4 mt-10 bg-blue-400 hover:bg-blue-300 text-white text-xl font-bold rounded-2xl cursor-pointer">
            <img class="mr-4" src="../../assets/images/landing/icons/Combined-Shape-path.png" alt="" />
            Перейти в бот
          </a>
        </div>
      </div>

      <div class="flex justify-end md:mt-0 mt-6">
        <img class="w-full" src="../../assets/images/landing/header-img.png" alt="">
      </div>
    </section>

    <section class="info grid sm:grid-cols-2 grid-cols-1 mt-32">
      <div class="">
        <h1 class="md:text-5xl text-4xl font-bold mb-20">
          <span class="text-blue-400">Заказывайте товары&nbsp;</span>
          <span>по всему миру</span>
        </h1>

        <div class="flex items-center mb-12">
          <img class="mr-12" src="../../assets/images/landing/icons/globe.png" alt="">
          <div class="text-xl">
            <p class="font-bold">Более 20 стран,</p>
            <p>предоставлящих услуги доставки</p>
          </div>
        </div>

        <div class="flex items-center mb-12">
          <img class="mr-12" src="../../assets/images/landing/icons/shop.png" alt="">
          <div class="text-xl">
            <p class="font-bold">400 магазинов,</p>
            <p>в которых можно совершать покупки</p>
          </div>
        </div>

        <div class="flex items-center mb-12">
          <img class="mr-12" src="../../assets/images/landing/icons/customer.png" alt="">
          <div class="text-xl">
            <p class="font-bold">11 тысяч довольных</p>
            <p>покупателей по всей стране</p>
          </div>
        </div>

        <div class="flex items-center mb-12">
          <img class="mr-12" src="../../assets/images/landing/icons/plane.png" alt="">
          <div class="text-xl">
            <p class="font-bold">24 рейса в неделю</p>
            <p>доставляют посылки</p>
          </div>
        </div>
      </div>
      <div><img src="../../assets/images/landing/telegram-bot-phone.png" alt=""></div>
    </section>

    <section class="shops mt-32">
      <h1 class="md:text-5xl text-4xl font-bold mb-16">
        <span class="text-blue-400">Магазины&nbsp;</span>
        <span>для вас</span>
      </h1>

      <div v-if="country !== null">
        <div class="grid sm:grid-cols-2 grid-cols-1 gap-10">
          <div class="country-card cursor-pointer rounded-2xl relative overflow-hidden">

            <img class="w-full h-full" :src="country.image" :alt="country.name.eng">

            <img class="absolute bottom-0 right-0 h-full" src="../../assets/images/landing/country-image-overlay.png"
                 alt="">

            <span class="absolute bottom-7 right-6 text-white text-3xl font-bold z-10">
              {{ country.name.ru }}
            </span>
          </div>
          <div>
            <!-- <p class="mt-10 text-xl" style="opacity: 0;">
              В великобритании вы можете производить покупки в 24 магазинах, более подробный список ищите в нашем
              телеграм-боте
            </p> -->
            <a
              :href="botUrl"
              target="_blank"
              class="inline-flex items-center px-6 py-4 mt-16 bg-blue-400 hover:bg-blue-300 text-white text-xl font-bold rounded-2xl cursor-pointer">
              <img class="mr-4" src="../../assets/images/landing/icons/Combined-Shape-path.png" alt="" />
              Перейти в бот
            </a>
          </div>
        </div>

        <div v-if="brands[0]?.stores.length" class="brands">

          <div class="flex justify-around">
            <a
              v-for="category in categories"
              :key="category.id"
              href="#"
              class="underline text-primary"
              @click.prevent="selectCategory(category.id)"
            >
              {{ category.name.ru }}
            </a>
          </div>
          <!-- Slider main container -->
          <Swiper
            class="swiper"
            :slides-per-view="1"
            :space-between="10"
            :loop="false"
            :breakpoints="{
              // when window width is >= 320px
              575: {
                slidesPerView: 2,
                spaceBetween: 10
              },
              767: {
                slidesPerView: 4,
                spaceBetween: 10
              },
              991: {
                slidesPerView: 6,
                spaceBetween: 10
              }
            }"
            style="padding-bottom: 5px;"
            ref="swiperRef"
          >
            <!-- Additional required wrapper -->
            <!-- Slides -->
            <SwiperSlide v-for="(brand, index) in brandsByCategory.length ? brandsByCategory : brands[0]?.stores" :key="index">
              <a
                :href="brand.link"
                target="_blank"
                class="shadow-md hover:shadow-lg hover:cursor-pointer rounded-2xl w-full h-full flex flex-col justify-center p-4"
                style="height: 180px; background-color: #fff;"
              >
                <div class="h-50 d-flex align-items-center justify-content-center">
                  <img :src="brand.image" alt="" style="mix-blend-mode: multiply;">
                </div>
                <p class="h-50 text-xl text-center d-flex flex-column justify-content-end">
                  {{ brand.name }}
                </p>
              </a>
            </SwiperSlide>

            <!-- If we need pagination -->
<!--            <div class="swiper-pagination"></div>-->

            <!-- If we need navigation buttons -->
            <div class="swiper-button-prev" @click="moveSlide('prev')"></div>
            <div class="swiper-button-next" @click="moveSlide('next')"></div>
          </Swiper>
        </div>
        <template v-else>
          <br><br><br><br>
        </template>
      </div>

      <div v-if="countries.length" class="grid sm:grid-cols-2 grid-cols-1 gap-4">
        <div v-for="(country, index) in countries" :key="index"
             class="country-card cursor-pointer rounded-2xl relative overflow-hidden"
             @click="selectCountry(country.id)">

          <img class="w-full h-full" style="max-height: 225px;" :src="country.image" :alt="country.name.eng">

          <img class="absolute bottom-0 right-0 h-full" src="../../assets/images/landing/country-image-overlay.png"
               alt="">

          <span class="absolute bottom-7 right-6 text-white text-3xl font-bold z-10">
            {{ country.name.ru }}
            </span>
        </div>
      </div>
    </section>

    <section class="flights mt-32 mb-24">
      <h1 class="md:text-5xl text-4xl font-bold mb-4">
        <span class="text-blue-400">Рейсы&nbsp;</span>
        <span>с посылками</span>
      </h1>

      <p class="mb-10 text-xl">Вы можете узнать когда прилетит ваша посылка введя номер рейса и дату прилета</p>

      <div class="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-7">
<!--        <div class="flex items-center w-full">-->
<!--          <div class="relative w-full">-->
<!--            <select v-model="search.departure_country" class="focus:outline-none w-full border-blue-400 rounded-2xl py-4 px-6">-->
<!--              <option v-for="(country, index) in countries" :key="index" :value="country.id">-->
<!--                {{ country.name.ru }}-->
<!--              </option>-->
<!--            </select>-->
<!--            <span class="absolute -top-3 left-6 z-10 px-2 bg-white text-gray-500 rounded-lg">Выберите страну</span>-->
<!--          </div>-->
<!--        </div>-->

        <div class="flex items-center w-full">
          <div class="relative w-full">
            <input v-model="search.flight_number" type="text" class="focus:outline-none w-full border border-blue-400  rounded-2xl py-4 px-6">
            <span class="absolute -top-3 left-6 z-10 px-2 bg-white text-gray-500 rounded-lg" for="">Введите название рейса</span>
          </div>
        </div>

        <div class="flex items-center w-full">
          <div class="relative w-full">
            <input v-model="search.arrival_time" type="datetime-local" class="focus:outline-none w-full border border-blue-400  rounded-2xl py-4 px-6" />
            <span class="absolute -top-3 left-6 z-10 px-2 bg-white text-gray-500 rounded-lg" for="">Выберите дату прибытие</span>
          </div>
        </div>

        <div>
          <button
            class="inline-flex items-center px-6 py-4 bg-blue-400 hover:bg-blue-300 text-white text-xl font-bold rounded-2xl cursor-pointer w-full"
            :disabled="isLoading"
            @click="searchOrder"
          >
            <img class="mx-6" src="../../assets/images/landing/icons/search.png" alt="" />
            Найти рейс
          </button>
        </div>
      </div>

      <div class="shadow-lg rounded-2xl mt-10">
        <div v-if="isLoading">Загрузка...</div>

        <div v-else class="flex flex-col">
          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div class="overflow-x-auto">
                <table class="min-w-full">
                  <thead class="w-1/2">
<!--                  <tr>-->
<!--                    <td class="px-6 py-2.5 text-xl" colspan="6">Великобритания, Лондон — Узбекистан, Ташкент. Дата-->
<!--                      вылета 30.11.2021-->
<!--                    </td>-->
<!--                  </tr>-->
                  <tr class="bg-blue-400 text-white">
                    <td class="px-6 py-2.5 text-xl font-bold">No</td>
                    <td class="text-xl font-bold">Откуда</td>
                    <td class="text-xl font-bold">Куда (Кому)</td>
                    <td class="text-xl font-bold">Вылет</td>
                    <td class="text-xl font-bold">Прибытие</td>
<!--                    <td class="text-xl font-bold">Время в пути</td>-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="flight in flights" :key="flight.id">
                    <td class="px-6 py-2.5">{{ flight.flight_number }}</td>
                    <td>{{ flight.departure_country.name.ru }}</td>
                    <td>{{ flight.arrival_country.name.ru }}</td>
                    <td>{{ flight.departure_time }}</td>
                    <td>{{ flight.arrival_time }}</td>
<!--                    <td>-->
<!--                      {{ new Date(new Date(flight.arrival_time) - new Date(flight.departure_time)).toLocaleDateString() }}-->
<!--                    </td>-->
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>

  <footer class="bg-blue-400 py-6">
    <nav class="container mx-auto">
      <div class="grid md:grid-cols-4 grid-cols-1 gap-7">

        <div class="flex justify-center md:justify-start">
          <img src="/img/logo-secondary.png" alt="" srcset="" width="140" />
        </div>

        <div class="flex items-center justify-center">
          <a href="#" class="underline text-white">
            Политика конфиденциальности
          </a>
        </div>

        <div class="flex items-center justify-center ">
          <a href="#" class="underline text-white">Помощь</a>
        </div>

        <div>
          <a
            :href="botUrl"
            target="_blank"
            class="inline-flex items-center justify-around px-6 py-4 bg-white hover:bg-grey-100 text-blue-400 text-xl font-bold rounded-2xl cursor-pointer w-full">
            <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.2246 14.5013L9.81625 20.619C10.4162 20.619 10.6813 20.3446 11.0162 20.019L13.899 17.2159L19.8962 21.6468C20.9999 22.2558 21.7965 21.9405 22.0714 20.6221L26.0084 2.0695C26.4115 0.453463 25.3924 -0.279497 24.3366 0.199863L1.21857 9.11406C-0.359453 9.747 -0.350253 10.6274 0.930927 11.0197L6.86353 12.8713L20.5981 4.20632C21.2465 3.81314 21.8416 4.02453 21.3532 4.458L10.2242 14.501L10.2246 14.5013Z"
                fill="#30ABE6" />
            </svg>

            Перейти в бот
          </a>
        </div>
      </div>
    </nav>
  </footer>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.min.css'

import { getLandingCountries } from '@/services/countries.service'
import { getLandingShops } from '@/services/shop.service'
import { getLandingFlights } from '@/services/flights.service'
import { getLandingCategories } from '@/services/categories.service'

export default {
  name: 'landing',
  data: () => ({
    botUrl: 'https://t.me/qorabayirbot',
    brands: [],
    brandsByCategory: [],
    countries: [],
    flights: [],
    country: null,
    categories: null,
    search: {
      flight_number: undefined,
      arrival_time: undefined,
      departure_country: undefined
    },
    isLoading: false
  }),
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    moveSlide (movement) {
      if (movement === 'prev') {
        document.querySelector('.swiper').swiper.slidePrev()
      } else {
        document.querySelector('.swiper').swiper.slideNext()
      }
    },
    selectCategory (categoryId) {
      this.brandsByCategory = this.categories.find(category => category.id === categoryId).store
    },
    async fetchCategories () {
      const { data: categoriesResponse } = await getLandingCategories()

      this.categories = categoriesResponse.data
    },
    async selectCountry (countryId) {
      const { data: shopsResponse } = await getLandingShops(countryId)
      this.brands = shopsResponse

      this.country = this.countries.find((country) => country.id === countryId)

      document.querySelector('.shops').scrollIntoView({
        behavior: 'smooth'
      })
    },
    async fetchFlights (params) {
      this.isLoading = true
      const { data: flights } = await getLandingFlights(params)
      this.flights = flights
      this.isLoading = false
    },
    async searchOrder () {
      await this.fetchFlights(this.search)
    }
  },
  async created () {
    const { data: countriesResponse } = await getLandingCountries()
    this.countries = countriesResponse

    const [firstCountry] = this.countries
    this.country = firstCountry

    const { data: shopsResponse } = await getLandingShops(firstCountry?.id)
    this.brands = shopsResponse

    await this.fetchFlights()
    await this.fetchCategories()
  }
}
</script>

<style scoped>
@import "../../assets/output.css";

select {
  -webkit-appearance: none;
  background-color: white;
}

.brands {
  margin: 100px 0;
}

.swiper {
  height: auto;
  margin: 32px 0;
}

.swiper-wrapper {
  padding: 32px 0 !important;
}

.swiper-slide {
  height: calc((100% - 30px) / 2) !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 58px;
}

.swiper-pagination >>> .swiper-pagination-bullet {
  width: 15px;
  height: 4px;
  border-radius: 6px;
}

.swiper-pagination >>> .swiper-pagination-bullet-active {
  width: 25px;
  height: 4px;
  border-radius: 6px;
}

@media screen and (max-width: 575px) {
  .swiper {
    height: 215px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 991px) {
  .swiper-slide {
    height: calc((100% - 70px) / 2) !important;
  }
}
</style>
