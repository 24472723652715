import ApiClient from '../api'
import { Fligts } from '../models'

const api = new ApiClient(Fligts.modelName)
const landingApi = new ApiClient(Fligts.modelName, true)

export const getFlights = async (params) => {
  const { data: flights } = await api.getAll(params)

  return flights
}

export const getLandingFlights = async (params) => {
  const { data: flights } = await landingApi.getAll(params)

  return flights
}

export const createFlight = async (formData) => {
  const { data: flight } = await api.create(formData)

  return flight
}

export const updateFlight = async (formData) => {
  const { data: flight } = await api.update(formData)

  return flight
}

export const removeFlight = async (id) => {
  return await api.delete(id)
}
